@import "../../scss/variables/variables.scss";

.breadcrumb {
	margin-bottom: 1.8125em;

	ul {
		margin: 1.1em 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	li {
		display: inline-block;
		display: flex;
		align-items: center;

		&:not(:first-child) {
			&:before {
				content: '/';
				display: inline-block;
				margin: 0 0.5em;
				color: #9171BA;
				// color: rgba(29, 29, 27, 0.5);
			}
		}

		&:last-child {
			a {
				cursor: default;
				text-decoration: none;
				color: $color_withe;
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}
			}
		}

		a {
			font-size: 1em;
			// color: rgba(29, 29, 27, 0.5);
			text-decoration: none;
			color: #9171BA;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

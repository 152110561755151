.startScreen {
    background: #381466;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            display: block;
            object-fit: cover;
            object-position: center bottom;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.7em;

        @media (min-width: 768px) {
            font-size: 1em;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 3.875em;
        margin-bottom: 1em;
    }

    &__btn {
        width: 6.0625em;
        height: 6.0625em;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(124.35deg, #F7D378 20.25%, #FAB042 82.84%);
        box-shadow: 0 30px 70px rgba(0, 0, 0, 0.14);
        cursor: pointer;
    }

    .icon_prev {
        transform: rotate(180deg);
        color: #381466;
        display: flex;
        align-items: center;
        line-height: 0;
        font-size: 1.1em;

        &:after {
            content: '';
            border-top: 0.1em solid;
            width: 1.2em;
            margin-left: -0.3em;
        }
    }
}

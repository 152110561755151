$icomoon-font-family: "radio" !default;
$icomoon-font-path: "../../assets/fonts" !default;

$icon_input-checked: "\e90a";
$icon_edit-pencil: "\e90b";
$icon_online: "\e90c";
$icon_user: "\e903";
$icon_clock: "\e904";
$icon_lock: "\e905";
$icon_star: "\e906";
$icon_comment: "\e907";
$icon_logout: "\e908";
$icon_view: "\e909";
$icon_prev: "\e900";
$icon_play: "\e901";
$icon_email: "\e902";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6oqrdm") format('truetype'), url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6oqrdm") format('woff'), url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6oqrdm##{$icomoon-font-family}") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon_"],
[class*=" icon_"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon_input-checked {
    &:before {
        content: $icon_input-checked;
    }
}

.icon_edit-pencil {
    &:before {
        content: $icon_edit-pencil;
    }
}

.icon_online {
    &:before {
        content: $icon_online;
    }
}

.icon_user {
    &:before {
        content: $icon_user;
    }
}

.icon_clock {
    &:before {
        content: $icon_clock;
    }
}

.icon_lock {
    &:before {
        content: $icon_lock;
    }
}

.icon_star {
    &:before {
        content: $icon_star;
    }
}

.icon_comment {
    &:before {
        content: $icon_comment;
    }
}

.icon_logout {
    &:before {
        content: $icon_logout;
    }
}

.icon_view {
    &:before {
        content: $icon_view;
    }
}

.icon_prev {
    &:before {
        content: $icon_prev;
    }
}

.icon_play {
    &:before {
        content: $icon_play;
    }
}

.icon_email {
    &:before {
        content: $icon_email;
    }
}

.loader {
    overflow: hidden;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#381366, 0.7);

    &.open {
        height: 100%;
    }

    img {
        display: block;
        animation: rotate 2s infinite linear;
        width: 5em;
        height: 5em;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.form {
	background: #FFFFFF;
	// width: 28.0625em;
	box-shadow: 0 1.875em 4.375em rgba(0, 0, 0, 0.14);
	border-radius: 1.25em;
	padding: 2.1em 1.7em;
	color: #C1BACA;

	@media (min-width: 768px) {
		width: 28.0625em;
		border-radius: 1.25em;
		padding: 2.5em 2.8125em;
	}

	.login & {
		// float: right;
		position: relative;
		z-index: 2;

		@media (min-width: 768px) {
			margin-right: 6.125em;
		}
	}

	&__field,
	&__row {
		.btn,
		input:not([type]),
		input[type=date],
		input[type=email],
		input[type=number],
		input[type=password],
		input[type=search],
		input[type=tel],
		input[type=text],
		input[type=url],
		textarea {
			width: 100%;
		}

		textarea {
			max-width: 100%;
			max-width: 100%;
			min-height: 9.375em;
		}
	}

	&__field {
		position: relative;
	}

	&__icon {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 3em;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #C1BACA;

		.icon_view {
			cursor: pointer;

			&.closed {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					display: block;
					width: 100%;
					border-top: 0.125em solid;
					transform: rotate(45deg);
				}
			}
		}
	}

	&__row {
		margin-bottom: 0.875em;

		&_inline {
			display: flex;
			gap: 0.875em;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 2.375em;
		line-height: 120%;
		color: #381466;
		margin-bottom: 0.25em;
	}

	&__sub {
		font-weight: 600;
		font-size: 1em;
		line-height: 170%;
		color: #C1BACA;
		margin-bottom: 2.1875em;
	}

	&__label {
		display: block;
		font-size: 0.875em;
		margin-bottom: 1em;
	}

	&__action {
		margin: 1.6875em 0;
		text-align: center;

		.btn {
			width: 100%;
			margin-bottom: 0.875em;
		}

		&_inline {
			display: flex;
			align-items: center;
			text-align: left;

			.btn {
				flex: none;
				width: auto;
				margin-right: 1.1875em;
				margin-bottom: 0;
			}
		}
	}

	&__info {
		font-size: 14px;
	}

	&__footer {
		font-weight: 600;
		font-size: 1em;
		line-height: 170%;
		color: #381466;
		margin-top: 3em;
		padding: 1.6875em 2.8125em 0;
		margin: 0 -2.8125em;
		text-align: center;
		border-top: 1px solid rgba(56, 20, 102, 0.1);
	}
}
// Input text and textarea ================================================
input:not([type]),
input[type=date],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea {
	background-color: $input_bg_color;
	border: 0.0625em solid rgba(56, 20, 102, 0.2);
	border-radius: 0.625em;
	font-family: $main_font_family;
	color: #381466;
	padding: 1.25em 3em 1.25em 1.5em;
	outline: none;
	font-size: 0.875em;
	font-weight: 600;
	transition: 0.3s;

	&:hover {
		border: 1px solid $input_hover_border_color;
		background-color: $input_hover_bg_color;
	}

	&:focus {
		border: 1px solid $input_focus_border_color;
		background-color: $input_focus_bg_color;
	}

	&.invalid {
		background-color: $input_error_bg_color;
		border: 1px solid $input_error_border_color;
	}
	// Placeholder for input +++++++++++++++
	@include placeholder {
		color: #C1BACA;
	}
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
	appearance: none;
}

/* Для Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	height: auto;
	appearance: none;
	margin: 0;
}
/* Для Firefox */
input[type=number] {
	appearance: textfield;
}

// Input checkbox and radio ===============================================
input[type=checkbox],
input[type=radio] {
	padding: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex: none;
	height: 14px;
	width: 14px;
	color: #aaa;
	background: 0 0;
	border: 1px solid;
	overflow: hidden;
	vertical-align: middle;
	appearance: none;
	outline: 0;
	text-align: center;
	position: relative;

	&:not(:disabled),
	&:not(:disabled) {
		cursor: pointer;
	}

	&:before,
	&:before {
		display: block;
	}

	&:disabled,
	&:disabled {
		color: #ddd;
	}
}

input[type=radio] {
	border-radius: 50%;

	&:checked:before {
		content: '';
		width: 6px;
		height: 6px;
		display: block;
		border-radius: 50%;
		background: #000;
	}

	&:disabled:checked:before {
		background-color: #aaa;
	}
}

input[type=checkbox] {
	&:checked:before,
	&:indeterminate:before {
		content: "";
		width: 40%;
		height: 60%;
		color: #000;
		margin-top: -3px;
		border-right: 1px solid;
		border-bottom: 1px solid;
		transform: rotate(45deg);
	}

	&:indeterminate:before {
		content: "";
	}

	&:disabled:checked:before,
	&:disabled:indeterminate:before {
		color: #aaa;
	}
}

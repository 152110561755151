/*!
 *
 * Evgeniy Ivanov - 2018
 * busforward@gmail.com
 * Skype: ivanov_ea
 *
 */
@import 'mixins/mixin'; // Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,900);
@import 'core/fonts';
// Import files
@import 'variables/variables';
@import 'core/reset';
@import 'core/icon';
@import 'core/template';
// @import 'core/header';
// @import 'core/navbar';
@import 'core/form';
@import 'core/grid';
@import 'core/soc';
@import 'core/pagination';
@import 'core/buttons';
@import 'core/video';

* {
    // border: 1px solid #000;
}

.hidden {
    display: none !important;
}

.page__title {
    font-size: 3em;
    margin: 0 0 1.125em;

    @media (min-width: 768px) {
        font-size: 4.25em;
    }
}

.nav {
    background: linear-gradient(180deg, #451A75 0%, #7B2A8D 100%);
    border: 1px solid rgba(175, 58, 136, 0.1);
    box-sizing: border-box;
    box-shadow: 1.25em 1.875em 6.25em rgba(0, 0, 0, 0.2);
    border-radius: 6.25em;
    // display: flex;
    position: absolute;
    z-index: 12;
    margin: 0.75em;
    padding: 0.3125em;
    width: calc(100% - 1.5em);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
        display: block;
        padding: 0.3125em 0.3125em 2.125em;
        margin: 1.25em;
        width: auto;
    }

}

.logo {
    position: relative;
    z-index: 10;
    width: 4em;
    display: block;

    @media (min-width: 768px) {
        margin-bottom: 3.13em;
        width: 5em;
    }
}

.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    // display: none;

    @media (min-width: 768px) {
        margin: 0 1.2em;
        // writing-mode: tb-rl;
        display: flex;
        align-items: center;
        flex-direction: column;
        // transform: rotate(180deg);
    }

    @media (max-width: 767px) {
        position: fixed;
        padding: 6em 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, #451A75 0%, #7B2A8D 100%);
        transform: translateY(-100%);
        transition: transform 0.5s;

        &.open {
            transform: translateY(0);
        }
    }

    &__item {
        margin-top: 2.5em;
        margin-bottom: 0;

        @media (min-width: 768px) {
            margin-bottom: 2em;
            margin-top: 0;
        }

        a {
            font-size: 1.4em;
            font-weight: 600;
            text-decoration-color: transparent;

            @media (min-width: 768px) {
                font-size: 0.875em;
                display: block;
                margin: auto;
                text-align: center;

                span {
                    display: block;
                }
            }

            &:hover {
                color: lighten(#FFBE3F, 20%);
                // text-decoration-color: $color_withe;
            }

            &.active {
                color: #FFBE3F;
                // text-decoration-color: $color_withe;
            }
        }
    }

    &__toggle {
        display: none;
        @media (max-width: 767px) {
            margin-right: 1.6em;
            display: block;
            position: relative;
            width: 1.7em;
            height: 1.5em;
            border-top: 1px solid $color_withe;
            border-bottom: 1px solid $color_withe;
            z-index: 10;
            // transform: rotate(90deg);

            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                width: 100%;
                border-top: 1px solid $color_withe;
                transform: translateY(-50%) rotate(0);
                transition: transform 0.4s;
            }

            &:before {
                // top: 33.3%;
                // width: 70%;
            }

            &:after {
                // top: 66.6%;
            }

            &.active {
                border-color: transparent;

                &:after,
                &:before {
                    top: 50%;
                    width: 100%;
                }

                &:before {
                    transform: translateY(-50%) rotate(45deg);
                }

                &:after {
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }
    }

}

.login {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &Bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.dashboard {
    &__body {
        // margin-left: -1.5em;

        @media (min-width: 768px) {
            display: flex;
            margin-left: 0;
        }
    }

    &__nav {
        // width: 16.25em;
        flex: none;
        margin-right: 0.5em;

        @media (min-width: 768px) {
            margin-right: 5.9375em;
            width: 16.25em;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            line-height: 1.7;

            @media (max-width: 767px) {
                display: flex;
                margin: 0 0 1em;
            }
        }

        li {
            @media (max-width: 767px) {
                flex-grow: 1;
                text-align: center;
            }
        }

        a {
            font-weight: 600;
            text-decoration: none;
            padding: 1em 1.2em;
            text-align: center;
            display: block;
            // border-bottom: 1px solid rgba($color_withe, 0.2);

            @media (min-width: 768px) {
                text-align: left;
                display: flex;
                align-items: center;
                padding: 1.5em;
                border-bottom: 1px solid rgba($color_withe, 0.2);
            }

            &.active {
                background: linear-gradient(124.35deg, #F7D378 20.25%, #FAB042 82.84%);
                box-shadow: 0 1.875em 4.375em rgba(0, 0, 0, 0.14);
                border-radius: 0.625em;
                border-bottom: 0;
                color: #381466;
            }

            i {
                flex: none;
                width: 1.125em;
                text-align: center;

                @media (min-width: 768px) {
                    margin-right: 1.1875em;
                }
            }

            span {
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }

    }

    &__content {
        flex-grow: 1;
        min-width: 0;
    }

}

.whiteBox {
    background: $color_withe;
    border-radius: 0.7em;
    padding: 1em 0;
    color: #381466;
    font-weight: 600;
    line-height: 1.7;

    @media (min-width: 768px) {
        border-radius: 1.25em;
        padding: 1.625em 0;
    }

    &__body,
    &__head {
        padding: 0 1.4em 1em;
        margin-bottom: 1.1875em;

        @media (min-width: 768px) {
            padding: 0 2.625em 1.875em;
            margin-bottom: 1.1875em;
        }
    }

    &__head {
        border-bottom: 1px solid rgba(56, 20, 102, 0.1);
    }

    &__footer {
        padding: 0 1.4em 1em;
        border-top: 1px solid rgba(56, 20, 102, 0.1);

        @media (min-width: 768px) {
            padding: 0 2.625em 1.875em;
        }
    }
}

.userData {
    &__row {
        display: flex;
        align-items: baseline;
        margin-bottom: 0.75em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__label {
        margin-right: 0.5em;
    }

    &__prop {}

    &__form {
        display: flex;
        align-items: center;
        padding: 0;

        input {
            border: 0;
            padding: 0;
            border-radius: 0;
            border-bottom: 1px solid #ddd;

            &:hover {
                border: 0;
                border-bottom: 1px solid #ddd;
            }

            &:focus {
                border: 0;
                border-bottom: 1px solid darken(#ddd, 15%);
            }
        }

    }

    &__edit {
        cursor: pointer;
        font-size: 0.8em;
        margin-left: 1em;
        color: #FAB042;
    }

    &__save {
        cursor: pointer;
        border: 0;
        background: transparent;
        font-size: 1.3em;
        color: #FAB042;
    }

    &__decline {
        display: block;
        margin-left: 0.5em;
        position: relative;
        width: 1em;
        height: 1em;
        cursor: pointer;

        &:after,
        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            color: red;
            border-top: 0.125em solid;
            top: 50%;
        }

        &:before {
            transform: translateY(-50%) rotate(45deg);

        }

        &:after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }

}

.resetPass {

    @media (min-width: 768px) {
        display: flex;
        align-items: flex-end;
        gap: 1.5em;
        margin-bottom: -1.5em;
    }

    &__col {
        margin-bottom: 1em;

        @media (min-width: 768px) {
            flex-grow: 1;
            margin-bottom: 0;
        }
    }

    &__action {
        // align-self: flex-end;
    }

}

.favorite {
    position: absolute;
    background: url("../assets/img/favorit.svg") 0 0 no-repeat;
    background-size: contain;
    display: block;
    // border: 0.1875em solid yellow;
    right: 1.3em;
    top: 1.3em;
    width: 1.36em;
    height: 1.3em;
    cursor: pointer;
    z-index: 2;
    font-size: 1.3em;

    @media (min-width: 768px) {
        font-size: 1em;
    }

    .bookPage & {
        font-size: 2em;
    }

    &.active {
        background-image: url("../assets/img/favorit-active.svg");
    }
}

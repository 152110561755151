// Pagination ==================================================================

.pagination {
	margin: 5.8125em 0 2em;
	width: 100%;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;

	&.page-1 {
		// display: none;
	}

	li {
		display: inline-block;

		a {
			display: block;
			text-align: center;
			font-size: 1.125em;
			color: #9171BA;
			cursor: pointer;
			margin: 0 1em;
		}

		&.selected a {
			color: #F7D174;
			cursor: default;
		}

		&.next,
		&.previous {
			a {
				&:before {}
			}
		}

		&.previous {
			a {
				&:before {
					content: '<';

				}
			}
		}

		&.next {
			a {
				&:before {
					content: '>';

				}
			}
		}
	}
}

.player {
    display: flex;
    align-items: center;
    // height: 2.5em;
    position: relative;
    border-radius: 2.5em;
    background: #fff;
    font-size: 1.5em;
    overflow: hidden;

    @media (min-width: 768px) {
        font-size: 1em;
    }

    &__loader {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#fff, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2.5em;

        &El,
        &El:after,
        &El:before {
            border-radius: 50%;
            width: 1em;
            height: 1em;
            animation-fill-mode: both;
            animation: load7 1.8s infinite ease-in-out;
        }

        &El {
            top: -1em;
            color: #ddd;
            font-size: 0.625em;
            margin: auto;
            position: relative;
            transform: translateZ(0);
            animation-delay: -0.16s;
        }

        &El:after,
        &El:before {
            content: '';
            position: absolute;
            top: 0;
        }

        &El:before {
            left: -1em;
            animation-delay: -0.32s;
        }

        &El:after {
            left: 1em;
        }
        @-webkit-keyframes load7 {
            0%,
            100%,
            80% {
                box-shadow: 0 1em 0 -1.3em;
            }

            40% {
                box-shadow: 0 1em 0 0;
            }
        }
        @keyframes load7 {
            0%,
            100%,
            80% {
                box-shadow: 0 1em 0 -1.3em;
            }

            40% {
                box-shadow: 0 1em 0 0;
            }
        }
    }

    audio {
        display: none;
    }

    &__btn {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(124.35deg, #F7D378 20.25%, #FAB042 82.84%);
        width: 2.1875em;
        height: 2.1875em;
        margin: 0.15em 0.5625em 0.15em 0.15em;
        border-radius: 50%;
        position: relative;
        z-index: 4;
        cursor: pointer;

        .bookPage & {
            @media (min-width: 768px) {
                width: 4.875em;
                height: 4.875em;

                .icon_play {
                    font-size: 1.3em;
                }

                .icon_pause {
                    font-size: 2em;
                }
            }
        }

        .icon_play {
            font-size: 0.6em;
            line-height: 0;
        }

        .icon_pause {
            width: 0.6em;
            height: 0.6em;
            position: relative;
            display: flex;
            justify-content: space-between;

            &:after,
            &:before {
                content: '';
                flex: none;
                width: 0.1875em;
                border-radius: 0.1875em;
                background: #fff;
            }

            &:before {}

            &:after {}
        }
    }

    &__progress {
        flex-grow: 1;
        margin-right: 0.7em;
        background: #D8D8D8;
        position: relative;

        .bookPage & {
            @media (min-width: 768px) {
                margin-right: 1em;
            }
        }

        img {
            width: 100%;
            display: block;
            position: relative;
        }
    }

    &__bar {
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #AF3A88;
        width: 100%;
    }

    input[type=range] {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
    }

}

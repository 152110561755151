.liveBtn {
    position: fixed;
    right: 2em;
    bottom: 2em;
    width: 8.25em;
    height: 8.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    z-index: 13;
    font-size: 0.7em;

    @media (min-width: 768px) {
        font-size: 1em;
    }

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        // top: 0;
        // right: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &:before {
        background: linear-gradient(180deg, #451A75 0%, #7B2A8D 100%);
        box-shadow: 0 1.875em 6.25em rgba(0, 0, 0, 0.1);
        transform: scale(1) rotate(120deg);
        z-index: 1;
    }

    &:after {
        width: 6.25em;
        height: 6.25em;
        background: linear-gradient(180deg, #451A75 0%, #7B2A8D 100%);
        opacity: 0.5;
        box-shadow: 0 1.875em 4.375em rgba(0, 0, 0, 0.1);
        z-index: 2;
    }

    &.active {
        &:before {
            animation: bumBox1 2s infinite;
        }

        &:after {
            animation: bumBox2 2s infinite;
        }
    }

    // &.active {
    //     color: yellow;
    // }

    i {
        // font-size: 1.5em;
    }

    .icon_loader {
        display: block;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: rgba(#fff, 0.3);
        border-radius: 50%;

        &:before {
            content: '';
            display: block;
            color: #ffffff;
            font-size: 1.8em;
            overflow: hidden;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            margin: 4.5em auto;
            transform: translateZ(0);
            animation: load6 1.7s infinite ease, round 1.7s infinite ease;
        }
    }

    &__inner {
        position: relative;
        width: 3.75em;
        height: 3.75em;
        background: linear-gradient(180deg, #451A75 0%, #7B2A8D 100%);
        box-shadow: 0 1.875em 4.375em rgba(0, 0, 0, 0.14);
        transform: scale(1) rotate(-75deg);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;

        .active & {
            animation: bumBox3 2s infinite;
        }

        i {
            transform: rotate(75deg);
            position: relative;
        }

        .icon_play {
            display: block;

            .active & {
                display: none;
            }
        }

        .icon_pause {
            display: none;
            width: 0.5em;
            height: 0.9375em;

            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                background: #fff;
                width: 0.125em;
                height: 100%;
                border-radius: 0.0625em;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }

            .active & {
                display: block;
            }
        }
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes bumBox1 {
    0% {
        transform: scale(1) rotate(120deg);
    }

    5% {
        transform: scale(1) rotate(120deg);
    }

    25% {
        transform: scale(0.8) rotate(120deg);
    }

    50% {
        transform: scale(1) rotate(120deg);
    }
}

@keyframes bumBox2 {
    0% {
        transform: scale(1);
    }

    3% {
        transform: scale(1);
    }

    20% {
        transform: scale(0.8);
    }

    45% {
        transform: scale(1);
    }
}

@keyframes bumBox3 {
    0% {
        transform: scale(1) rotate(-75deg);
    }

    15% {
        transform: scale(0.8) rotate(-75deg);
    }

    40% {
        transform: scale(1) rotate(-75deg);
    }

    5% {
        transform: scale(1) rotate(-75deg);
    }
}

.footer {
    background: url("../../../assets/img/footer-bg-mob.png") 0 bottom no-repeat;
    background-size: 100% auto;
    position: relative;
    padding: 4.5em 0 14.8em;

    @media (min-width: 768px) {
        padding: 17em 0 4em;
        background: none;
        position: relative;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        @media (max-width: 767px) {
            // background: #4C2B80;
        }

    }

    &__bg {
        display: none;
        @media (min-width: 768px) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 30.75em;

            img {
                display: block;
                object-fit: contain;
                object-position: center bottom;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__apples {
        top: -5em;
        display: block;
        width: 100%;
        z-index: 1;
        text-align: center;
        margin-bottom: 2em;

        @media (min-width: 768px) {
            position: absolute;
            left: 0;
            top: 0;
        }

        img {
            width: 16em;

            @media (min-width: 768px) {
                width: 31.0625em;
            }
        }
    }

    &__logo {
        margin-bottom: 3.5625em;
        width: 7.6em;
        margin-top: 3em;
        order: 10;

        @media (min-width: 768px) {
            width: 11.25em;
            margin-top: 0;
            order: -1;
        }
    }

    &__nav {
        width: 100%;
        @media (min-width: 768px) {
            max-width: 72.0625em;
            max-width: 72.0625em;
            padding-left: 1em;
            padding-right: 1em;
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {
        text-align: center;
        margin-bottom: 3em;

        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }

    &__header {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 0.75em;

        @media (min-width: 768px) {
            font-size: 2em;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.625em;
    }

}

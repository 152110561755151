// Обертка для адаптивного видео
//	<div class="video__wrapper js-youtube" id="ID_VIDEO">
//		iframe c youtube
// </div>
.video {
	&__wrapper {
		position: relative;
		overflow: hidden;
		padding-bottom: 56.4%;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		.video__prev,
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__prev {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__play {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 7.2em;
		height: 7.2em;
		margin-top: -3.6em;
		margin-left: -3.6em;
		border-radius: 50%;
		cursor: pointer;
		// background-color: #f00;
	}
}
